import { Observable, of } from "rxjs";
import {
  ISelectItem,
  TDictionaryField
} from "ngx-strong-frontend-lib/interfaces";
import { UniversalGridUtilsService } from 'ngx-strong-frontend-lib/grid/services/universal-grid-utils'
import { DictionaryApiService } from "@core/services/api/dictionary-api.service";
import { TranslateService } from "@ngx-translate/core";
import { share } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { DateTimeService } from "ngx-strong-frontend-lib/services/date-time";
import { FilterType } from "ngx-strong-frontend-lib/enums";

@Injectable()
export class DcUniversalGridUtilsService extends UniversalGridUtilsService {

  constructor(
    private dictionaryApiService: DictionaryApiService,
    protected translateService: TranslateService,
    protected dateTimeService: DateTimeService
  ) {
    super(translateService, dateTimeService);
  }

  public getDictionaryType(dictCode: string): FilterType.select | FilterType.dictionary {
    return ['RES_TYPE', 'DEV_TYPE', 'USD_DRIVER_TYPE', 'USD_DEV_TYPE', 'USD_INTERACTION_PROTOCOL']
      .includes(dictCode)
      ? FilterType.select
      : FilterType.dictionary;
  }

  public getSelectList(dictCode: string): Observable<ISelectItem[]> {
    let result: Observable<ISelectItem[]> = of([]);
    switch (dictCode) {
      case 'RES_TYPE': {
        result = this.dictionaryApiService.getResourceTypeList([], false);
        break;
      }
      case 'DEV_TYPE': {
        result = this.dictionaryApiService.getDeviceTypeList([], true);
        break;
      }
      case 'USD_DRIVER_TYPE': {
        result = this.dictionaryApiService.getUsdDriverTypeList();
        break;
      }
      case 'USD_DEV_TYPE': {
        result = this.dictionaryApiService.getUsdDeviceTypeList();
        break;
      }
      case 'USD_INTERACTION_PROTOCOL': {
        result = this.dictionaryApiService.getUsdInteractionProtocolsList()
        break;
      }
    }
    return result.pipe(share());
  }

  public getDictionary(dictCode: string): TDictionaryField {
    if (dictCode === 'USD_EVENT_TYPE') {
      return {
        request$: (params) => this.dictionaryApiService.getUsdEventTypeDictionary({
          ...params,
          ...{ onlyActual: false }
        })
      }
    }

    return {
      request$: (params) => of(null)
    }
  }
}
